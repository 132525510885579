.contact {
  text-align: center;
}

.contact h2 {
  font-size: 40px;
  margin-bottom: 80px;
  margin-top: 80px;
  font-family: Bungee, FangSong;
}

.contact p {
  margin: 10px 0;
  width: fit-content;
  margin-bottom: 5%;
}

.contact-icon {
  margin-right: 5px;
  vertical-align: middle;
}

.contacts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -40%);
}

.contact-icon {
  width: 35px;
  height: 35px;
  color: black;
  background-color: white;
  border-radius: 20%;
  background-size: cover;
  margin-right: 10px;
}
.contact-icon { 
  padding: 10px; 
}

.copyable { 
  cursor: pointer; 
  position: relative;
}

.copyable:hover .copy-alert {
  visibility: visible;
  opacity: 1;
}

.copy-alert {
  position: absolute;
  top: -30px;
  left: 100%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

@media (max-width: 515px) {
  .contact-icon {
      width: 25px;
      height: 25px;
  }
  .contact p {
      font-size: 14px;
      margin-bottom: 30px;
  }

  .copyable {
      font-size: 14px;
      margin-bottom: 30px;
  }

  .contacts {
      width: 250px;
  }
}

@media (max-height: 750px) {
  .contacts {
      top: 0%;
      left: 0%;
      transform: translate(43%, 90%);
  }
}

@media (max-height: 500px) {
  .contacts {
      top: 50%;
      left: 50%;
      transform: translate(-40%, 20%);
  }
}