.About {
  text-align: center;
}

.About h2 { 
  font-size: 40px;
  font-family: Bungee, FangSong;
  margin-bottom: 80px;
  margin-top: 80px;
}

.para {
  width: 100%;
  margin: 0 auto;
}

.para p {
  max-width: 550px;
  margin: 0 auto;
  line-height: 1.75;
  font-size: 16px;
  margin-bottom: 3%;
}

@media (max-width: 655px) {
  .About h2 { 
      font-size: 40px;
      margin-bottom: 80px;
      margin-top: 80px;
  }

  .para p {
      max-width: 400px;
      font-size: 12px;
  }
}

@media (max-width: 500px) {
  .para p {
      max-width: 300px;
      font-size: 12px;
  }

  .para {
      margin-bottom: 75px;
  }
}
