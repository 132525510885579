.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #373737;
  color: #ffffff;
  font-weight: 600;
}

.logo img {
  width: 55px;
  height: 55px;
  border-radius: 20%;
  background-size: cover;
}

.nav-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.hamburger {
  display: none;
  cursor: pointer;
  padding: 10px;
  flex-shrink: 0;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: #ffffff;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .hamburger {
      display: block;
  }

  .side {
      display: none;
  }

  .nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #373737;
      padding: 10px 0;
  }

  .nav-link {
      margin: 10px 10px;
      text-align: center;
      justify-content: flex-end;
  }

  .nav-container {
      justify-content: flex-end;
  }

  .nav-container.mobile-open .nav {
      display: flex;
      width: fit-content;
      padding: 10px;
      flex-grow: 1;
      right: -20px;
      left: auto;
      z-index: 1000;
  }
}

.nav-link {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
  font-size: 18px;
}

.nav-link:hover {
  color: #ffcc00;
}

.side-icon {
  width: 35px;
  height: 35px;
  color: black;
  background-color: white;
  border-radius: 20%;
  background-size: cover;
  margin-right: 10px;
  padding: 10px;
}
