.Home {
  display: flex;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center h2 {
  font-size: 40px;
  font-family: Bungee, FangSong;
  margin-bottom: 80px;
  margin-top: 80px;
}

.center img {
  height: 300px;
  width: 360px;
  border-radius: 20%;
}

.shown {
  margin-left: 7%;
  margin-top: 15%;
}

.side-com {
  margin-left: 7%;
  margin-top: 15%;
}

.intro {
  font-size: 25px;
  font-weight: 600;
}

.side-com span {
  font-weight: bold;
  font-size: 30px;
  width: 500px;
}

.body {
  width: 300px;
  font-size: 17px;
}


@media (max-width: 1170px) {
  .intro {
      font-size: 17px;
      font-weight: 600;
  }
  
  .side-com span {
      font-weight: bold;
      font-size: 20px;
  }
  
  .body {
      font-size: 14px;
      width: 200px;
  }

}

@media (max-width: 900px) {
  .center img {
      height: 240px;
      width: 300px;
  }
  .body {
    width: 300px;
  }
}

@media (max-width: 835px) {
  .hidden {
      display: flex;
      align-items: center;
      margin-bottom: 75px;
      width: 300px;
  }

  .side-com {
      margin-left: 0%;
      margin-top: 15%;
      width: 300px;
  }

  .shown {
      display: none;
  }
}

@media (max-height: 500px) { 
  .body {
      width: 150px;
  }
}
